import React, { Component, Suspense, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import './app.scss';
import claim_logo from '../../../assets/logos/comjoodoc_logo_claim.png';
import sanecumLogo from '../../../assets/logos/sanecum-logo.png';
import HeaderContent from '../Header/HeaderContent';
import FooterContent from '../Footer/FooterContent';
import { connect } from 'react-redux';
import ErrorPage from '../../../module/errorPage/pages/Errorpage/ErrorPage';

const { Header, Footer, Content } = Layout;
const claimLogos: any = {
  sanecum: {
    logoPath: sanecumLogo,
  },
  comjoo: {
    logoPath: claim_logo,
  },
};

const meta: any = {
  sanecum: {
    title: 'Sanecum Mein Arzt',
    description: 'Sanecum Mein Arzt',
    favicon: `${process.env.PUBLIC_URL}/sanecum-favicon.ico`,
    manifest: `${process.env.PUBLIC_URL}/sanecum-manifest.json`,
  },
  comjoo: {
    title: 'comjoodoc',
    description: 'comjoodoc',
    favicon: `${process.env.PUBLIC_URL}/favicon.ico`,
    manifest: `${process.env.PUBLIC_URL}/manifest.json`,
  },
};

interface Props {}

interface State {}
class App extends Component<Props, State> {
  public static defaultProps: Partial<Props> = {};
  public auth: any = null;

  /**
   *
   */
  render = () => {
    let routes = (
      <Switch>
        <Route path="/error" exact component={ErrorPage}></Route>
        <Redirect to="/error" />
      </Switch>
    );

    return (
      <Fragment>
        {/*
        Programmatically change meta information based on the selected theme
        */}
        <Helmet>
          <title>{meta[window._env_.THEME_ENV].title}</title>
          <meta
            name="description"
            content={meta[window._env_.THEME_ENV].description}
          />
          <link rel="icon" href={meta[window._env_.THEME_ENV].favicon} />
          <link rel="manifest" href={meta[window._env_.THEME_ENV].manifest} />
        </Helmet>
        <Router>
          <Suspense
            fallback={
              <div
                style={{
                  backgroundColor: 'rgba(238, 238, 238, .3)',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <div className="loading-content">
                  <img
                    style={{ width: '30vw' }}
                    src={claimLogos[window._env_.THEME_ENV].logoPath}
                    alt="Logo"
                  />
                </div>
              </div>
            }
          >
            <Layout>
              <Header className="layout-content-header">
                <HeaderContent />
              </Header>

              <Content className="layout-content-wrapper">{routes}</Content>
              <Footer className="app-footer">
                <FooterContent />
              </Footer>
            </Layout>
          </Suspense>
        </Router>
      </Fragment>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
