import React from "react";
// translation
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import LanguageSwitch from "./LanguageSwitch";
// UI
import "./HeaderContent.scss";
import comjooLogo from "./../../../assets/logos/logo-comjoodoc.svg";
import sanecumLogo from "./../../../assets/logos/sanecum-logo-white.svg";

const logos: any = {
  sanecum: {
    logoPath: sanecumLogo,
    width: 52,
    title: "sanecum.title",
    classes: "sanecum-logo"
  },
  comjoo: {
    logoPath: comjooLogo,
    width: 120,
    title: "title",
    classes: "comjoo-logo"
  }
};

interface Props {}

const HeaderContent = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Row>
      {/* JSON.stringify(props) */}
      <Col span={12} className="logo-wrapper">
        {/* <img
          src={logos[window._env_.THEME_ENV].logoPath}
          alt="logo"
          className={logos[window._env_.THEME_ENV].classes + " page-logo"}
          style={{
            width: logos[window._env_.THEME_ENV].width
          }}
        /> */}
      </Col>
      <Col span={12} className="menu-lang">
        <LanguageSwitch />
      </Col>
    </Row>
  );
};

export default HeaderContent;
