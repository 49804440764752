import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Typography, Card } from 'antd';
import './ErrorPage.scss';
import i18n from '../../../../core/i18n/i18n';
import ErrorpageDE from './../../translation/de/ErrorPageDE.json';
import ErrorpageEN from './../../translation/en/ErrorPageEN.json';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IErrorpageProps, IErrorpageState } from './../../models/IErrorpage';

import claim_logo from '../../../../assets/logos/logo-comjoo-color-black-vert.svg';
import sanecumLogo from '../../../../assets/logos/sanecum-logo.png';

i18n.addResourceBundle('de', 'errorpage', ErrorpageDE);
i18n.addResourceBundle('en', 'errorpage', ErrorpageEN);

const { Content } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;
class ErrorPage extends React.Component<IErrorpageProps, IErrorpageState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <Content className="page-wrapper">
        <Row justify="center" className="margin-bottom-l">
          <Col span={24}>
            <Title className="text-center" level={2}>
              {i18n.t('errorpage:errorTitle')}
            </Title>
          </Col>
        </Row>
        <Row justify="center" className="margin-bottom-m">
          <Col span={24}>
            <Title className="text-center" level={4}>
              {i18n.t('errorpage:wantTo')}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="padding-right-m padding-left-m">
            <Row justify="center">
              <Card
                hoverable
                onClick={() => window.open('https://hub.comjoo.com')}
                className="card-item"
                cover={
                  <img
                    className="logo-item"
                    alt="logo comjoo"
                    src={claim_logo}
                  />
                }
              >
                <Meta title="Login comjoo" />
              </Card>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="info-wrapper">
          <Col xs={24} sm={20} md={18}>
            <Text className="text-underline">{i18n.t('errorpage:note')}:</Text>
            <Text className="info-text">
              {i18n.t('errorpage:bookmarkInfo')}
              <a className="link-item" href="hub.comjoo.com">
                hub.comjoo.com
              </a>
            </Text>
          </Col>
        </Row>
      </Content>
    );
  };
}

const mapStateToProps = (state: any, item: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default withTranslation()<any>(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPage)),
);
