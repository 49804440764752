import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './FooterContent.scss';
import { Row, Col } from 'antd';
import appleStore from './../../../assets/logos/appstore.png';
import googleStore from './../../../assets/logos/googlestore.png';

const appLinks: any = {
  // TODO check and refactor links if needed
  sanecum: {
    ios:
      'https://apps.apple.com/us/app/sanecum-mein-arzt/id1508288825?l=de&ls=1',
    android:
      'https://play.google.com/store/apps/details?id=com.comjoo.meinarzt',
  },
  comjoo: {
    ios: 'https://apps.apple.com/de/app/comjoodoc/id1429861556',
    android: 'https://play.google.com/store/apps/details?id=com.comjoodoc',
  },
};

const FooterContent: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Row className="footer-wrapper">
      <Col xs={0} sm={0} md={1} lg={1} xl={1} />
      <Col xs={24} sm={0} md={22} lg={22} xl={22} className="footer-content">
        <span>
          {window._env_.THEME_ENV !== 'sanecum' && (
            <>
              {`${t('comjooService')} `}
              <a className="margin-left-s" href="https://www.comjoodoc.de/">
                comjoo business solutions GmbH
              </a>
            </>
          )}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={appLinks[window._env_.THEME_ENV].ios}
            style={{ marginLeft: '7px', marginRight: '7px' }}
          >
            <img
              src={appleStore}
              style={{ height: '30px' }}
              alt="Android Store"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={appLinks[window._env_.THEME_ENV].android}
          >
            <img
              src={googleStore}
              style={{ height: '30px' }}
              alt="Android Store"
            />
          </a>
        </span>
      </Col>
      <Col xs={0} sm={0} md={1} lg={1} xl={1} />
    </Row>
  );
};

export default FooterContent;
